import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { deletePage } from "../../features/project/projectSlice";
import { Project, UserRole } from "../../lib/types"
import { AppDispatch, RootState } from "../../store";
import AddingPage from "./addingPage/addingPage";
import PageLink from "./pageLink/pageLink";

import styles from './Pages.module.css';

type PagesProps = {
    project: Project,
};

export type PageCreate = {
    name: string,
    parent_id?: number,
    in_nav?: boolean,
    project_id?: number,
}

export default function Pages({ project }: PagesProps ) {
    const userRole = useSelector((state: RootState) => state.project.value?.role);
    const [addingPage, setAddingPage] = useState<PageCreate|null>(null);
    const params = useParams();
    const pageId = params.pageId ? parseInt(params.pageId) : null;
    const editable = userRole !== UserRole.Client;
    const dispatch = useDispatch<AppDispatch>();

    const handleDeletePage = (id: number) => {
        dispatch(deletePage(id));
    }

    return (<div className={styles.pages}>
        {project.pages.map(page => <div className={styles.pageContainer} key={page.id}>
            <PageLink page={page} active={page.id === pageId} />
            {page.subpages?.map(subpage => <div className={styles.subPageContainer} key={subpage.id}><PageLink page={subpage} active={subpage.id === pageId} /></div>)}
            {userRole !== UserRole.Client && <>
                {! addingPage && <button className={styles.subPageButton} onClick={() => setAddingPage({name: '', in_nav: false, parent_id: page.id})}>+</button>}
                {(addingPage && addingPage.parent_id === page.id) && <div className={styles.subPageContainer}>
                    <AddingPage project={project} addingPage={addingPage} setAddingPage={setAddingPage} />
                </div>}
            </>}
            {editable && <div className={styles.pageDragHandle}>
                <button onClick={() => handleDeletePage(page.id)}>x</button>
            </div>}
        </div>)}
        {userRole !== UserRole.Client && <>
            {(addingPage && !addingPage.parent_id) && <AddingPage project={project} addingPage={addingPage} setAddingPage={setAddingPage} />}
            {! addingPage && <div className={styles.addPage} onClick={() => setAddingPage({
                name: '',
                in_nav: false,
                project_id: project?.id,
            })}>+</div>}
        </>}
    </div>)
}
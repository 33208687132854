import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateImage } from "../../features/project/projectSlice";
import { Project, UserRole, Image, Note, NotableType } from "../../lib/types";
import { AppDispatch, RootState } from "../../store";
import ImageGrid from "../images/imageGrid/imageGrid";
import Notes from "../notes/notes";

import styles from './ProjectDashboard.module.css';
import ProjectPalette from "./projectPalette/projectPalette";
import ProjectUsers from "./projectUsers/projectUsers";

type User = {
    id?: number,
    name: string,
    email: string,
    pivot?: {
        role: UserRole,
    }
}

export type Color = {
    name: string,
    hex: string,
};

export type DashboardProject = Project & {
    palette: {
        id: number,
        colors: (Color & {id: number})[],
    },
    users: User[],
    logos: Image[],
    projectImages: Image[],
    role: UserRole,
    notes: Note[],
}

export default function ProjectDashboard() {
    const params = useParams();
    const projectId = params.projectId ? parseInt(params.projectId) : undefined;
    const project = useSelector((state: RootState) => state.project.value);
    const dispatch = useDispatch<AppDispatch>();

    /**
     * Take uploaded image, and add it as a project logo, when returned, push to logo for current project
     * 
     * @param image 
     */
    const makeLogo = (image: Image) => {
        dispatch(updateImage({image_id: image.id, is_logo: true}));
    }

    /**
     * Take uploaded image, and add it as a project image, when returned, push to projectImages for current project
     * 
     * @param image 
     */
    const makeProjectImage = (image: Image) => {
        dispatch(updateImage({image_id: image.id, is_project_image: true}));
    }

    return (<div className={styles.dashboard}>
        {project && <>
            <ProjectUsers project={project} />
            <ProjectPalette project={project} />
        </>}
        <h2>Logos</h2>
        {(projectId && project) && 
            <ImageGrid images={project.logos} projectId={projectId} onAdd={makeLogo} />}
        <h2>Other Brand Images</h2>
        {(projectId && project) && 
            <ImageGrid images={project.projectImages} projectId={projectId} onAdd={makeProjectImage} />}
        {project && <Notes notes={project.notes} type={NotableType.Project} notable_id={project.id} />}
    </div>);
}
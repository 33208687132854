import { Section } from "../../../lib/types";
import SectionContentDisplay from "../sectionContent/sectionContent";
import classNames from "classnames";
import styles from './WireframeSection.module.css';
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { deleteSection } from "../../../features/page/pageSlice";

type WireframeSectionProps = {
    section: Section,
    index: number,
    numSections: number,
    movable: boolean,
    moveSection: (dragIndex: number, hoverIndex: number) => void,
};

export default function WireframeSection({section, index, numSections, movable, moveSection}: WireframeSectionProps) {
    const dispatch = useDispatch<AppDispatch>();

    const handleDeleteSection = (id: number) => {
        dispatch(deleteSection(id));
    }

    return (<div className={classNames({
            [styles.section]: true,
            [styles.active]: section.active
        })}>
        {section.columns.map(column => <div 
            key={column.id}
            className={styles.column}
            style={{
                flexBasis: `${column.width}%`,
            }}><SectionContentDisplay content={column.content} />
            </div>)}
        {movable && <>
            <div className={styles.sectionDragHandle}>
                <button type="button" onClick={() => moveSection(index, Math.max(0, index - 1))} disabled={index <= 0}><img src="/assets/images/icons/chevron-up-solid.svg" alt=""/></button>
                <button type="button" onClick={() => moveSection(index, Math.min(index + 1, numSections - 1))} disabled={index >= numSections - 1}><img src="/assets/images/icons/chevron-down-solid.svg" alt=""/></button>
            </div>
            <button className={styles.deleteButton} onClick={() => handleDeleteSection(section.id)}>x</button>
        </>}
    </div>);
}
export enum ElementType {
    text,
    heading,
    image,
}

export enum UserRole {
    Developer,
    AccountManager,
    Client,
}

export enum ContentStatus {
    New,
    Draft,
    Approved,
}

export enum NotableType {
    Project,
    Page,
    Content,
};

export type Element = {
    id: number,
    type: ElementType,
    name: string,
    order: number,
    prompt?: string,
    value?: string,
    images?: Image[],
}

export type Template = {
    name: string,
    elements: Element[],
}

export type SectionContent = {
    id: number,
    name: string,
    elements: Element[],
    status: ContentStatus,
    notes: Note[],
}

export type Column = {
    id: number,
    width: number,
    content: SectionContent,
}

export type Section = {
    id: number,
    order: number,
    columns: Column[],
    active?: boolean,
}

export type Page = {
    id: number,
    name: string,
    sections: Section[],
    subpages?: Page[],
    in_nav?: boolean,
}

export type Project = {
    id: number,
    name: string,
    pages: Page[],
}

export type Image = {
    id: number,
    path: string,
    filename: string,
    alt?: string,
    description?: string,
    is_logo?: boolean,
    is_project_image?: boolean,
}

export type Note = {
    id: number,
    content: string,
    user_id: number,
}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AddingSection } from "../../components/activePage/activePage";
import { Page, Section, SectionContent } from "../../lib/types";
import { callDelete, getResource, postResource, put, putResource } from "../../services/api/api.service";

type PageState = {
    value: Page | null,
    loading: boolean,
}

const initialState: PageState = {
    value: null,
    loading: false,
}

export const fetchPage = createAsyncThunk('page/fetchPage', async (page_id: number) => {
    const page = await getResource(`pages/${page_id}`);
    return page; 
});

type EditPageData = {
    page_id: number,
    name?: string,
}

export const updatePage = createAsyncThunk('page/editPage', async (editPageData: EditPageData) => {
    const {page_id, ...data} = editPageData;
    
    const page = await putResource(`pages/${page_id}`, data);
    return page; 
});

type SaveOrderData = {
    page_id: number
};

export const saveOrder = createAsyncThunk('page/saveOrder', async (data: SaveOrderData) => {
    const {page_id, ...order} = data;

    const page = put(`pages/${page_id}/sectionOrder`, {order});
    return page;
});

type AddSectionData = AddingSection & {
    page_id: number,
};

export const addSection = createAsyncThunk('page/addSection', async (data: AddSectionData) => {
    const {page_id, ...addingSection} = data;
    const section = postResource(`pages/${page_id}/sections/`, addingSection);
    return section;
});

export const deleteSection = createAsyncThunk('page/deleteSection', async (id: number) => {
    const response = callDelete(`sections/${id}`);
    return response;
});

export const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        setSections: (state: PageState, { payload }: { payload: Section[] }) => {
            if (!state.value) return;
            
            state.value.sections = payload;
        },
        updateContentState: (state: PageState, { payload }: {payload: SectionContent}) => {
            if (! state.value) return;

            // Find section and column indexes
            let sectionIndex = null, columnIndex = null;
            state.value.sections.forEach((section, sIndex) => {
                section.columns.forEach((column, cIndex) => {
                    if (column.content.id === payload.id) {
                        sectionIndex = sIndex;
                        columnIndex = cIndex;
                    }
                });
            });

            if (sectionIndex === null || columnIndex === null) return;

            state.value.sections[sectionIndex].columns[columnIndex].content.status = payload.status;
        },
        setActiveSection: (state: PageState, { payload }: { payload: number }) => {
            if (! state.value) return;

            state.value.sections = state.value.sections.map(section => ({
                ...section,
                active: section.columns.some(column => column.content.id === payload),
            }));
        }
    },
    extraReducers(builder) {
        builder.addCase(fetchPage.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchPage.fulfilled, (state, {payload}) => {
            state.value = payload;
            state.loading = false;
        });
        builder.addCase(fetchPage.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(updatePage.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updatePage.fulfilled, (state, {payload}) => {
            state.value = payload;
            state.loading = false;
        });
        builder.addCase(updatePage.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(saveOrder.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(saveOrder.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(saveOrder.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(addSection.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addSection.fulfilled, (state, {payload}) => {
            if (!state.value) return;

            state.value.sections.push(payload);
            state.loading = false;
        });
        builder.addCase(addSection.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(deleteSection.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteSection.fulfilled, (state, {payload}) => {
            if (! state.value) return;

            state.value.sections = state.value.sections.filter(section => section.id !== payload.id);
            state.loading = false;
        });
        builder.addCase(deleteSection.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });
    },
});

export default pageSlice.reducer;

export const {setSections, updateContentState, setActiveSection} = pageSlice.actions;
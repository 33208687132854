import { ChangeEvent, FormEvent } from "react";
import { useDispatch } from "react-redux";
import { addPage } from "../../../features/project/projectSlice";
import { Project } from "../../../lib/types";
import { AppDispatch } from "../../../store";
import { PageCreate } from "../pages";

import styles from './AddingPage.module.css';
import utilStyles from '../../../styles/Utils.module.css';

type AddingPageProps = {
    project: Project,
    addingPage: PageCreate,
    setAddingPage: (page: PageCreate | null) => void,
}

export default function AddingPage({ addingPage, setAddingPage}: AddingPageProps) {
    const dispatch = useDispatch<AppDispatch>();
    
    const changeName = (e: ChangeEvent) => {
        const target = e.target as typeof e.target & {
            value: string
        };
        
        setAddingPage({
            ...addingPage,
            name: target.value,
        });
    }

    const changeInNav = (e: ChangeEvent) => {
        const target = e.target as typeof e.target & {
            checked: boolean
        };

        if (! addingPage) return;

        setAddingPage({
            ...addingPage,
            in_nav: target.checked,
        });
    }

    const savePage = async (e: FormEvent) => {
        e.preventDefault();

        dispatch(addPage(addingPage));
        setAddingPage(null);
    }
    
    return (<form className={utilStyles.componentForm} onSubmit={savePage}>
        <input 
            type="text" 
            name="name" 
            placeholder="Page Name" 
            onChange={changeName}
            className={utilStyles.formInput} />
        <div className={utilStyles.checkbox}>
            <label htmlFor="in_nav">In Nav?</label>
            <input type="checkbox" name="in_nav" onChange={changeInNav} />
        </div>
        <div className={utilStyles.formButtons}>
            <button className={utilStyles.buttonLink} type="button" onClick={() => setAddingPage(null)}>Cancel</button>
            <input className={utilStyles.buttonPrimary} type="submit" value="Save" />
        </div>
    </form>);
}
import { ChangeEvent, useState } from "react";
import styles from "./EditableHeading.module.css";
import utilStyles from "../../styles/Utils.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { UserRole } from "../../lib/types";

type EditableHeadingProps = {
    children: string,
    className?: string,
    onSave?: (newValue: string) => void
}

export default function EditableHeading({ children, className = '', onSave }: EditableHeadingProps) {
    const [editing, setEditing] = useState<boolean>(false);
    const [heading, setHeading] = useState<string>(children);
    const userRole = useSelector((state: RootState) => state.project.value?.role);
    const editable = userRole !== UserRole.Client && !!onSave;

    const handleHeadingChange = (e: ChangeEvent<HTMLInputElement>) => {
        setHeading(e.target.value);
    }

    const handleSaveButtonClicked = () => {
        if (onSave) onSave(heading);
        setEditing(false);
    }
    
    return (<div className={className}>
        {editing 
            ? <div className={utilStyles.componentForm}>
                <input type="text" className={utilStyles.formInput} value={heading} onChange={handleHeadingChange} />
                <div className={utilStyles.formButtons}>
                    <button type="button" className={utilStyles.buttonLink} onClick={() => setEditing(false)}>Cancel</button>
                    <button type="button" className={utilStyles.buttonPrimary} onClick={handleSaveButtonClicked}>Save</button>
                </div>
            </div>
            : <div className={styles.headingContainer}>
                <h2 className={`${utilStyles.headingSecondary}`}>{children}</h2>
                {editable && <button className={styles.editButton} type="button" onClick={() => setEditing(true)}>
                    <img src="/assets/images/icons/pen-solid.svg" alt="" />
                </button>}
            </div>}
    </div>);
}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Project } from "../../lib/types";
import { getResource, postResource } from "../../services/api/api.service";

export type User = {
    id: number,
    name: string,
    email: string,
    projects: Project[],
}

export type AddingProject = {
    name: string,
    user_id?: number,
}

type UserState = {
    value: User | null,
    authorized: boolean,
    loading: boolean,
}

const initialState: UserState = {
    value: null,
    authorized: false,
    loading: true,
};

export const fetchCurrentUser = createAsyncThunk('user/fetchCurrentUser', async (_, thunkApi) => {
    const token = localStorage.getItem('token');

    if (! token) return thunkApi.rejectWithValue('token not found');
    
    const user = await getResource('user/auth');
    return user;
});

export const addProject = createAsyncThunk('user/addProject', async (addingProject: AddingProject) => {
    const project = postResource('projects', addingProject);
    return project;
});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout: (state: UserState) => {
            localStorage.removeItem('token')
            state.value = null;
            state.authorized = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCurrentUser.pending, (state) => {
            state.loading = true;
            state.authorized = false;
        });
        builder.addCase(fetchCurrentUser.fulfilled, (state, {payload}) => {
            state.value = payload;
            state.authorized = true;
            state.loading = false;
        });
        builder.addCase(fetchCurrentUser.rejected, (state) => {
            state.loading = false;
            state.authorized = false;
        });
        
        builder.addCase(addProject.fulfilled, (state, {payload}) => {
            if (!state.value) return;
            state.value.projects = [...state.value.projects, payload];

            state.authorized = true;
            state.loading = false;
        });;
    },
});

export default userSlice.reducer;

export const { logout } = userSlice.actions;
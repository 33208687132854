import { createAsyncThunk, createSlice, isRejectedWithValue, PayloadAction } from '@reduxjs/toolkit';
import { PageCreate } from '../../components/pages/pages';
import { Color, DashboardProject } from '../../components/projectDashboard/projectDashboard';
import { Image, Note, Project, UserRole } from '../../lib/types';
import { callDelete, getResource, postResource, putResource } from '../../services/api/api.service';

type ProjectState = {
    value: DashboardProject | null,
    loading: boolean,
    error: string | null,
}

const initialState: ProjectState = {
    value: null,
    loading: true,
    error: null,
}

export const addPage = createAsyncThunk('project/addPage', async (addingPage: PageCreate) => {
    if (! addingPage.project_id) return isRejectedWithValue('project_id not set');

    const page = await postResource(`projects/${addingPage.project_id}/pages/`, addingPage);
    return page;
});

export const deletePage = createAsyncThunk('project/deletePage', async (id: number) => {
    const response = callDelete(`pages/${id}`);
    return response;
});

export const fetchProject = createAsyncThunk('project/fetchProject', async (projectId: number) => {
    const project = await getResource(`projects/${projectId}`);
    return project;
});

type ColorWithPaletteId = Color & {
    palette_id: number, 
};

export const addColor = createAsyncThunk('project/addColor', async (addingColor: ColorWithPaletteId) => {
    const { palette_id, ...data} = addingColor;
    const color = await postResource(`palettes/${palette_id}/colors`, data);
    return color;
});

type UpdatingImage = {
    image_id: number,
    is_logo?: boolean,
    is_project_image?: boolean,
}

export const updateImage = createAsyncThunk('project/updateImage', async (updatingImage: UpdatingImage) => {
    const {image_id, ...data} = updatingImage;
    const image: Image = await putResource(`images/${image_id}`, data);
    return image;
});

type AddingUser = {
    project_id: number,
    email: string,
    role: UserRole,
};

export const addUser = createAsyncThunk('project/addUser', async (addingUser: AddingUser) => {
    const {project_id, ...data} = addingUser;
    const user = await postResource(`projects/${project_id}/users`, data);
    return user;
});

type AddingNote = Omit<Note, 'id'> & {
    project_id: number,
};

export const addProjectNote = createAsyncThunk('project/addNote', async (addingNote: AddingNote) => {
    const {project_id, ...data} = addingNote;
    const note = await postResource(`projects/${project_id}/notes`, data);
    return note;
});

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProject.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchProject.fulfilled, (state, {payload}) => {
            state.value = payload;
            state.loading = false;
        });
        builder.addCase(fetchProject.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(addPage.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addPage.fulfilled, (state, {payload}) => {
            if (! state.value) return;
            
            state.value.pages.push(payload);
            state.loading = false;
        });
        builder.addCase(addPage.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(deletePage.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deletePage.fulfilled, (state, {payload}) => {
            if (! state.value) return;

            state.value.pages = state.value.pages.filter(page => page.id !== payload.id);
            state.loading = false;
        });
        builder.addCase(deletePage.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(addColor.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addColor.fulfilled, (state, {payload}) => {
            state.loading = false;
            
            if (!state.value) return;
            state.value.palette.colors.push(payload);
        });
        builder.addCase(addColor.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(updateImage.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateImage.fulfilled, (state, {payload}) => {
            state.loading = false;
            
            if (!state.value) return;

            if (payload.is_logo) {
                state.value.logos.push(payload);
            }

            if (payload.is_project_image) {
                state.value.projectImages.push(payload);
            }
        });
        builder.addCase(updateImage.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(addUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addUser.fulfilled, (state, {payload}) => {
            state.loading = false;
            
            if (!state.value) return;

            state.value.users.push(payload);
        });
        builder.addCase(addUser.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(addProjectNote.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addProjectNote.fulfilled, (state, {payload}) => {
            state.loading = false;
            
            if (!state.value) return;

            state.value.notes.push(payload);
        });
        builder.addCase(addProjectNote.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });
    },
});

export default projectSlice.reducer;
import { UserRole } from "../../../lib/types"
import { DashboardProject } from "../projectDashboard";

import styles from './ProjectUsers.module.css';
import utilStyles from '../../../styles/Utils.module.css';
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { addUser } from "../../../features/project/projectSlice";

type ProjectUsersProps = {
    project: DashboardProject,
};

type AddingUser = {
    email: string,
    role: UserRole,
}

export default function ProjectUsers({ project }: ProjectUsersProps) {
    const [addingUser, setAddingUser] = useState<AddingUser|null>(null);
    const dispatch = useDispatch<AppDispatch>();
    
    const renderRole = (role: UserRole) => {
        switch (role) {
            case UserRole.Developer:
                return 'Developer';
            case UserRole.AccountManager:
                return 'Account Manager';
            case UserRole.Client:
                return 'Client';
        }
    }

    const handleAddingUserEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!addingUser) return;

        setAddingUser({
            ...addingUser,
            email: e.target.value,
        });
    }

    const handleAddingUserRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (!addingUser) return;

        setAddingUser({
            ...addingUser,
            role: parseInt(e.target.value),
        })
    }

    const addUserToProject = () => {
        if (!addingUser) return;

        dispatch(addUser({project_id: project.id, ...addingUser}));
        setAddingUser(null);
    }

    return (<div className={styles.projectUsers}>
        <h2>Users</h2>
        {project.users.map(user => <div key={user.id}>
            <div>{user.name}: {user.email} &#40;{user.pivot && renderRole(user.pivot.role)}&#41;</div>
        </div>
        )}
        {addingUser 
            ? <div className={utilStyles.componentForm}>
                <input type="email" value={addingUser.email} onChange={handleAddingUserEmailChange} placeholder='Existing user email' className={utilStyles.formInput} />
                <select value={addingUser.role} onChange={handleAddingUserRoleChange} className={utilStyles.formInput}>
                    <option value={UserRole.Developer}>Developer</option>
                    <option value={UserRole.AccountManager}>Account Manager</option>
                    <option value={UserRole.Client}>Client</option>
                </select>
                <div className={utilStyles.formButtons}>
                    <button type="button" onClick={() => setAddingUser(null)} className={utilStyles.buttonLink}>Cancel</button>
                    <button type="button" onClick={addUserToProject} className={utilStyles.buttonPrimary}>Add</button>
                </div>
            </div>
            : <button 
                className={utilStyles.buttonPrimary}
                onClick={() => setAddingUser({
                    email: '',
                    role: UserRole.Developer,
                })}>+</button>}
    </div>)
}
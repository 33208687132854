import {useEffect} from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import Pages from "../../components/pages/pages";
import { useDispatch, useSelector } from 'react-redux';

import styles from './Project.module.css';
import { AppDispatch, RootState } from "../../store";
import { fetchProject } from "../../features/project/projectSlice";
import Layout from "../../components/layout/layout";
import LoadingOverlay from "../../components/loadingOverlay/loadingOverlay";

export default function Project() {
    const { projectId } = useParams();
    const { value: project, loading } = useSelector((state: RootState) => state.project);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (! projectId) return;

        dispatch(fetchProject(parseInt(projectId)));
    }, [projectId, dispatch]);

    return (<Layout>
        <div className={styles.projectContainer}>
            <div className={styles.projectPages}>
                <Link to='/projects'>&lt; Back</Link>
                <h2>Pages</h2>
                {project && <Pages project={project} />}
            </div>
            <Outlet />
            {loading && <LoadingOverlay />}
        </div>
    </Layout>);
}
import { ChangeEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProjectNote } from "../../features/project/projectSlice";
import { NotableType, Note } from "../../lib/types";
import { AppDispatch, RootState } from "../../store";

import styles from './Notes.module.css';
import utilStyles from '../../styles/Utils.module.css';
import { addContentNote } from "../../features/content/contentSlice";

type NotesProps = {
    notes: Note[],
    type: NotableType,
    notable_id: number,
}

export default function Notes({ notes, type, notable_id }: NotesProps) {
    const [addingNote, setAddingNote] = useState<Omit<Note, 'id'>|null>(null);
    const user = useSelector((state: RootState) => state.user.value);
    const projectUsers = useSelector((state: RootState) => state.project.value?.users);
    const dispatch = useDispatch<AppDispatch>();

    const handleAddingNoteContentChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (!addingNote) return;

        setAddingNote({
            ...addingNote,
            content: e.target.value,
        });
    }

    const saveAddingNote = () => {
        if (!user || !addingNote) return;

        switch (type) {
            case NotableType.Project:
                dispatch(addProjectNote({project_id: notable_id, ...addingNote}));
                break;
            case NotableType.Content:
                dispatch(addContentNote({content_id: notable_id, ...addingNote}));
                break;
        }
        setAddingNote(null);
    }

    return (<div className={styles.notesContainer}>
        <h3 className={utilStyles.headingTertiary}>Notes</h3>
        <div className={styles.notes}>
            {notes.map(note => <div className={styles.note} key={note.id}>
                <div>{note.content}</div>
                {projectUsers && <div className={styles.userName}>{projectUsers.find(projectUser => projectUser.id === note.user_id)?.name}</div>}
            </div>)}
        </div>
        {user && (addingNote
            ? <div className={utilStyles.componentForm}>
                <textarea value={addingNote.content} onChange={handleAddingNoteContentChange} className={utilStyles.formInput}></textarea>
                <div className={utilStyles.formButtons}>
                    <button onClick={() => setAddingNote(null)} className={utilStyles.buttonLink}>Cancel</button>
                    <button className={utilStyles.buttonPrimary} onClick={saveAddingNote}>Submit</button>
                </div>
            </div>
            : <button className={utilStyles.buttonPrimary} onClick={() => setAddingNote({
                content: '',
                user_id: user.id,
            })}>+</button>)}
    </div>)
}
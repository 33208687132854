
import { saveAs } from 'file-saver';
import { ChangeEvent, FormEvent, useRef, useState } from 'react';
import { Image } from '../../../lib/types';
import { postFormResource } from '../../../services/api/api.service';
import styles from './ImageGrid.module.css';
import utilStyles from '../../../styles/Utils.module.css';

type ImageGridProps = {
    images: Image[],
    projectId: number,
    editable?: boolean,
    onAdd?: (image: Image) => void,
}

type AddingImage = Omit<Image, "id" | "path">;

export default function ImageGrid({ images, projectId, onAdd, editable = true }: ImageGridProps) {
    const [addingImage, setAddingImage] = useState<AddingImage|null>(null);
    const addingImageForm = useRef<HTMLFormElement>(null);

    const handleImageAdd = (e: ChangeEvent<HTMLInputElement>) => {
        if (! addingImage) return;

        if (e.target.files && e.target.files.length > 0) {
            setAddingImage({
                ...addingImage,
                filename: e.target.files[0].name,
            });
        }
    }

    const handleFilenameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (! addingImage) return;
        
        setAddingImage({
            ...addingImage,
            filename: e.target.value,
        });
    }

    const handleAltChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (! addingImage) return;
        
        setAddingImage({
            ...addingImage,
            alt: e.target.value,
        });
    }

    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (! addingImage) return;

        setAddingImage({
            ...addingImage,
            description: e.target.value,
        });
    }

    const handleUpload = () => {
        const formData = new FormData(addingImageForm.current as any);

        postFormResource(`projects/${projectId}/images`, formData)
            .then((image: Image) => {
                if (onAdd) onAdd(image);
                setAddingImage(null);
            });
    }
    
    return (<div className={styles.imageGrid}>
        {images.map(image => <div className={styles.item} onClick={() => saveAs(`${process.env.REACT_APP_STORAGE_BASE}${image.path}`, image.filename)} key={image.id}>
            <img src={`${process.env.REACT_APP_STORAGE_BASE}${image.path}`} alt={image.alt} />
        </div>)}
        {editable && (addingImage 
        ? <form className={`${utilStyles.componentForm}`} ref={addingImageForm}>
            <input type="file" name="image" onChange={handleImageAdd} className={utilStyles.formInput} />
            <input type="text" name="filename" value={addingImage.filename} onChange={handleFilenameChange} placeholder='Filename' className={utilStyles.formInput} required />
            <input type="text" name="alt" value={addingImage.alt} onChange={handleAltChange} placeholder='Alt' className={utilStyles.formInput} />
            <input type="text" name="description" value={addingImage.description} onChange={handleDescriptionChange} placeholder='Description'  className={utilStyles.formInput}/>
            <div className={utilStyles.formButtons}>
                <button type="button" onClick={() => setAddingImage(null)} className={utilStyles.buttonLink}>Cancel</button>
                <button type="button" onClick={handleUpload} className={utilStyles.buttonPrimary}>Upload</button>
            </div>
        </form>
        : <button className={styles.item} onClick={() => setAddingImage({
            filename: '',
            alt: '',
            description: '',
        })}>+</button>)}
        
    </div>);
}
import { ContentStatus, Element, Image } from "../../../../lib/types";
import ImageGrid from "../../../images/imageGrid/imageGrid";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { putResource } from "../../../../services/api/api.service";

import styles from "./ImageElement.module.css";
import { useState } from "react";

type ImageElementProps = {
    element: Element,
    status?: ContentStatus,
}

export default function ImageElement({ element, status }: ImageElementProps) {
    const projectId = useSelector((state: RootState) => state.project.value?.id);
    const {alt, description, filename} = element.images?.[0] ?? {alt: null, description: null, filename: null};

    const addImageToElement = (image: Image) => {
        // const existingIds = element.images?.length ? element.images?.map(image => image.id) : [];

        putResource(`elements/${element.id}`, {image_ids: [image.id]});
    }

    const copyText = (text: string | undefined) => {
        navigator.clipboard.writeText(text ?? '');
    };

    return (<div className={styles.imageElement}>
        {(element.images && projectId) && 
            <ImageGrid images={element.images} projectId={projectId} onAdd={addImageToElement} editable={status !== ContentStatus.Approved && element.images?.length < 1} />}
        {status === ContentStatus.Approved && <div className={styles.imageData}>
            {filename && <div className={styles.imageDataItem}>
                <strong>Filename:</strong>
                <span>{filename}</span>
                <button type="button" onClick={() => copyText(filename)} className={styles.copyButton}>
                    <img src="/assets/images/icons/copy-regular.svg" alt="" />
                </button>
            </div>} 
            {alt && <div className={styles.imageDataItem}>
                <strong>Alt:</strong>
                <span>{alt}</span>
                <button type="button" onClick={() => copyText(alt)} className={styles.copyButton}>
                    <img src="/assets/images/icons/copy-regular.svg" alt="" />
                </button>
            </div>}
            {description && <div className={styles.imageDataItem}>
                <strong>Description:</strong>
                <span>{description}</span>
                <button type="button" onClick={() => copyText(description)} className={styles.copyButton}>
                    <img src="/assets/images/icons/copy-regular.svg" alt="" />
                </button>
            </div>}
        </div>}
    </div>)
}
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { fetchCurrentUser, logout } from '../../features/user/userSlice';
import { AppDispatch, RootState } from '../../store';
import styles from './Layout.module.css';
import utilStyles from '../../styles/Utils.module.css';
import LoadingOverlay from '../loadingOverlay/loadingOverlay';

type LayoutProps = {
    children: JSX.Element | JSX.Element[],
};

export default function Layout({ children }: LayoutProps) {
    const dispatch = useDispatch<AppDispatch>();
    const { loading, authorized, value } = useSelector((state: RootState) => state.user);

    useEffect(() => {
        if (! value) {
            dispatch(fetchCurrentUser());
        }
    }, [value])

    return (<>
    {loading
        ? <LoadingOverlay />
        : authorized 
            ? <div className={styles.page}>
                    <div className={styles.header}>
                        <img className={styles.logo} src='/logo.svg' alt='sarox logo' />
                        <div>
                            <button className={utilStyles.button} onClick={() => dispatch(logout())}>Logout</button>
                        </div>
                    </div>
                    <main className={styles.main}>
                        {children}
                    </main>
                </div>
            : <Navigate to="/login" replace={true} /> 
    }
    </>);
}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ContentStatus, Element, ElementType, Note, SectionContent } from "../../lib/types";
import { getResource, postResource, putResource, callDelete, put } from "../../services/api/api.service";

type ContentState = {
    value: SectionContent | null,
    loading: boolean,
};

const initialState: ContentState = {
    value: null,
    loading: false,
}

export const fetchContent = createAsyncThunk('content/fetchContent', async (content_id: number) => {
    const content = await getResource(`contents/${content_id}`);
    return content;
});

export type AddingElement = {
    content_id: number,
    type: ElementType | null,
    name: string,
    prompt?: string,
    value?: string,
}

export const addElement = createAsyncThunk('content/addElement', async (addingElement: AddingElement) => {
    const {content_id, ...data} = addingElement;
    const element = await postResource(`contents/${content_id}/elements`, data);
    return element;
});

export const deleteElement = createAsyncThunk('content/deleteElement', async (id: number) => {
    const response = await callDelete(`elements/${id}`);
    return response;
});

type SaveElementOrderData = {
    content_id: number,
    order: {id: number, order: number}[],
}

export const saveElementOrder = createAsyncThunk('content/saveElementOrder', async (data: SaveElementOrderData) => {
    const {content_id, ...order} = data;

    const content = put(`contents/${content_id}/elementOrder`, order);
    return content;
});

type SaveContentData = {
    content_id: number,
    status: ContentStatus,
}

export const saveFormContent = createAsyncThunk('content/saveContent', async (data: SaveContentData) => {
    const {content_id, ...formData} = data;
    
    const content = postResource(`contents/${content_id}/data`, formData);
    return content;
});

type UpdateContentData = {
    content_id: number,
    status?: ContentStatus,
    name?: string,
}

export const updateContent = createAsyncThunk('content/updateContent', async (updateContentData: UpdateContentData) => {
    const {content_id, ...data} = updateContentData;

    const content = putResource(`contents/${content_id}`, data);
    return content;
});

type AddingNote = Omit<Note, 'id'> & {
    content_id: number,
};

export const addContentNote = createAsyncThunk('content/addNote', async (addingNote: AddingNote) => {
    const {content_id, ...data} = addingNote;
    const note = await postResource(`contents/${content_id}/notes`, data);
    return note;
});

export const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        setElements: (state: ContentState, {payload}: {payload: Element[]}) => {
            if (!state.value) return;

            state.value.elements = payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchContent.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchContent.fulfilled, (state, {payload}) => {
            state.value = payload;
            state.loading = false;
        });
        builder.addCase(fetchContent.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(addElement.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addElement.fulfilled, (state, {payload}) => {
            if (! state.value) return;

            state.value.elements.push(payload);
            state.loading = false;
        });
        builder.addCase(addElement.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(deleteElement.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteElement.fulfilled, (state, {payload}) => {
            if (! state.value) return;

            state.value.elements = state.value.elements.filter(element => element.id !== payload.id);
            state.loading = false;
        });
        builder.addCase(deleteElement.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(saveElementOrder.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(saveFormContent.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(saveFormContent.fulfilled, (state, {payload}) => {
            state.value = payload;
            state.loading = false;
        });
        builder.addCase(saveFormContent.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(updateContent.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(updateContent.fulfilled, (state, {payload}) => {
            state.value = payload;
            state.loading = false;
        });
        builder.addCase(updateContent.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });

        builder.addCase(addContentNote.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(addContentNote.fulfilled, (state, {payload}) => {
            state.loading = false;
            
            if (!state.value) return;

            state.value.notes.push(payload);
        });
        builder.addCase(addContentNote.rejected, (state) => {
            state.loading = false;
            state.value = null;
        });
    },
});

export default contentSlice.reducer;

export const {setElements} = contentSlice.actions;
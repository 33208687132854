import { ElementType, Element, SectionContent } from "../../../lib/types";
import ImageElement from "./imageElement/imageElement";
import LongText from "./longText/longText";
import ShortText from "./shortText/shortText";

import styles from "./ElementDisplay.module.css";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { deleteElement } from "../../../features/content/contentSlice";
import { useRef } from "react";

type ElementProps = {
    content: SectionContent,
    element: Element,
    editable: boolean,
    index: number,
    moveElement: (dragIndex: number, hoverIndex: number) => void,
}

export default function ElementDisplay({element, content, editable, index, moveElement}: ElementProps) {
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch<AppDispatch>();

    const handleDeleteElement = (id: number)  => {
        dispatch(deleteElement(id));
    }

    return (<div className={styles.element}>
        <div className={styles.elementName}>{element.name}</div>
        {element.prompt && <div className={styles.elementPrompt}>{element.prompt}</div>}
        {element.type === ElementType.heading && <ShortText element={element} status={content?.status} />}
        {element.type === ElementType.text && <LongText element={element} status={content?.status} />}
        {element.type === ElementType.image && <ImageElement element={element} status={content?.status} />}
        {editable && <>
            <div className={styles.operations}>
                <button type="button" onClick={() => moveElement(index, Math.max(0, index - 1))} disabled={index <= 0}><img src="/assets/images/icons/chevron-up-solid.svg" alt=""/></button>
                <button type="button" onClick={() => moveElement(index, Math.min(index + 1, content.elements.length - 1))} disabled={index >= content.elements.length - 1}><img src="/assets/images/icons/chevron-down-solid.svg" alt=""/></button>
            </div>
            <button type="button" className={styles.deleteButton} onClick={() => handleDeleteElement(element.id)}>x</button>
        </>}
    </div>);
}
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { UserRole } from "../../lib/types";

import styles from './ActivePage.module.css';
import utilStyles from '../../styles/Utils.module.css';

import { Outlet, useParams } from "react-router-dom";
import WireframeSection from "./wireframeSection/wireframeSection";
import { useDispatch, useSelector } from "react-redux";
import { addSection, fetchPage, saveOrder, setSections, updatePage } from "../../features/page/pageSlice";
import { AppDispatch, RootState } from "../../store";
import EditableHeading from "../editableHeading/editableHeading";
import LoadingOverlay from "../loadingOverlay/loadingOverlay";

type Column = {
    width: number,
    order: number,
    content: {
        name: string,
    }
};

export type AddingSection = {
    numColumns: number,
    columns: Column[],
};

export default function ActivePage() {
    const params = useParams();
    const pageId = params.pageId ? parseInt(params.pageId) : null;
    const {value: page, loading} = useSelector((state: RootState) => state.page);
    const dispatch = useDispatch<AppDispatch>();
    const [addingSection, setAddingSection] = useState<AddingSection|null>(null);
    const userRole = useSelector((state: RootState) => state.project.value?.role);
    const canEdit = userRole !== UserRole.Client;

    useEffect(() => {
        if (!pageId) return;

        dispatch(fetchPage(pageId));
    }, [pageId, dispatch]);

    const moveSection = (from: number, to: number) => {
        if (!page || !canEdit) return;

        let sections = [...page.sections];
        const item = sections.splice(from, 1)[0];
        sections.splice(to, 0, item);
        sections = sections.map((section, index) => ({
            ...section,
            order: index,
        }));

        dispatch(setSections(sections));

        const order = sections.map(section => ({id: section.id, order: section.order}));
        dispatch(saveOrder({
            page_id: page.id,
            ...order
        }));
    };

    const handleNumColsChange = (e: ChangeEvent<HTMLInputElement>) => {
        const numColumns = parseInt(e.target.value);

        let columns: Column[] = [];

        for (let i = 0; i < numColumns; i++) {
            columns.push({
                width: 100 / numColumns,
                order: i,
                content: {
                    name: '',
                },
            });
        }

        setAddingSection({
            numColumns,
            columns,
        });
    }

    const handleColumnWidthChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        if (!addingSection) return;

        let columns = addingSection.columns;
        if (!columns) return;
        
        columns[index].width = parseInt(e.target.value);

        setAddingSection({
            ...addingSection,
            columns,
        });
    }

    const handleColumnContentNameChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        if (!addingSection) return;

        let columns = addingSection.columns;
        if (!columns) return;
        
        columns[index].content.name = e.target.value;

        setAddingSection({
            ...addingSection,
            columns,
        });
    }

    const saveAddingSection = (e: FormEvent) => {
        e.preventDefault();

        if (!page || !addingSection) return;

        dispatch(addSection({
            page_id: page.id,
            ...addingSection,
        }));
        setAddingSection(null);
    }

    const handleHeadingSave = (newHeading: string) => {
        if (!page) return;

        dispatch(updatePage({
            page_id: page.id,
            name: newHeading,
        }));
    }

    return (<div className={styles.activePageContainer}>
        {page && <div className={styles.activePage}>
            <EditableHeading className="px-8 pb-4" onSave={handleHeadingSave}>{page.name}</EditableHeading>
            {page?.sections.map((section, index) => 
                <WireframeSection 
                    key={section.id} 
                    section={section} 
                    index={index}
                    numSections={page.sections.length} 
                    movable={canEdit} 
                    moveSection={moveSection} 
                />)}
            {canEdit && (addingSection 
                ? <form className={`${styles.addingSectionForm} ${utilStyles.componentForm}`} onSubmit={saveAddingSection}>
                    <label htmlFor="num-columns"># Columns</label>
                    <input className={utilStyles.formInput} type='number' id="num-columns" value={addingSection.numColumns} onChange={handleNumColsChange} />
                    {addingSection.columns.map((column, index) => <div className={styles.addingColumn} key={index}>
                        <label htmlFor={`col-${index}-name`}>Column {index + 1}</label>
                        <input className={utilStyles.formInput} type='text' id={`col-${index}-name`} value={column.content.name} placeholder='Name' onChange={(e: ChangeEvent<HTMLInputElement>) => handleColumnContentNameChange(e, index)} required />
                        <input  className={utilStyles.formInput} type='number' value={column.width} onChange={(e: ChangeEvent<HTMLInputElement>) => handleColumnWidthChange(e, index)} required />
                    </div>)}
                    <div className={utilStyles.formButtons}>
                        <button className={utilStyles.buttonDanger} type="button" onClick={() => setAddingSection(null)}>Cancel</button>
                        <button className={utilStyles.buttonPrimary} type="submit">Save</button>
                    </div>
                </form>
                : <div className={styles.addSection}>
                    <button className={styles.addSectionButton} onClick={() => setAddingSection({numColumns: 1, columns: [{
                        width: 100,
                        order: 1,
                        content: {
                            name: ''
                        },
                    }]})}>+</button>
                    </div>)}
        </div>}
        <Outlet />
        {loading && <LoadingOverlay />}
    </div>)
}
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addElement, AddingElement, deleteElement, fetchContent, saveElementOrder, saveFormContent, setElements, updateContent } from "../../features/content/contentSlice";
import { ContentStatus, ElementType, NotableType, UserRole } from "../../lib/types";
import { AppDispatch, RootState } from "../../store";

import styles from './ActiveSection.module.css';
import utilStyles from '../../styles/Utils.module.css';

import { setActiveSection, updateContentState } from "../../features/page/pageSlice";
import Notes from "../notes/notes";
import ElementDisplay from "./elementDisplay/elementDisplay";
import LoadingOverlay from "../loadingOverlay/loadingOverlay";
import EditableHeading from "../editableHeading/editableHeading";

export default function ActiveSection() {
    const {value: content, loading} = useSelector((state: RootState) => state.content);
    const userRole = useSelector((state: RootState) => state.project.value?.role);
    const params = useParams();
    const contentId = params.contentId ? parseInt(params.contentId) : null;
    const contentForm = useRef<HTMLFormElement>(null);
    const [addingElement, setAddingElement] = useState<Omit<AddingElement,'content_id'>|null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const editable = userRole !== UserRole.Client && content?.status !== ContentStatus.Approved;

    useEffect(() => {
        if (!contentId || (contentId === content?.id)) return;

        dispatch(fetchContent(contentId));
        dispatch(setActiveSection(contentId)); // TODO: this doesn't do anything on first load because the page value isn't set
    }, [contentId]);

    useEffect(() => {
        if (!content) return;

        dispatch(updateContentState(content));
    }, [content]);

    const saveAddingElement = (e: FormEvent) => {
        e.preventDefault();

        if (! content || ! addingElement) return;

        dispatch(addElement({content_id: content.id, ...addingElement}));
    }

    const handleTypeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if (!addingElement) return;

        setAddingElement({
            ...addingElement,
            type: e.target.value ? parseInt(e.target.value) : null,
        });
    }

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!addingElement) return;

        setAddingElement({
            ...addingElement,
            name: e.target.value,
        });
    }

    const handlePromptChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!addingElement) return;

        setAddingElement({
            ...addingElement,
            prompt: e.target.value,
        });
    }

    const handleFormSave = () => {
        saveContent(ContentStatus.Draft);
    }

    const handleFormApprove = () => {
        saveContent(ContentStatus.Approved);
    }

    const saveContent = (status: ContentStatus) => {
        if (!content) return;
        
        const formData = new FormData(contentForm.current as any);

        dispatch(saveFormContent({
            content_id: content.id,
            status,
            ...Object.fromEntries(formData),
        }));
    }

    const handleRevokeApproval = () => {
        if (!content) return;

        dispatch(updateContent({
            content_id: content.id,
            status: ContentStatus.Draft,
        }));
    }

    const moveElement = (from: number, to: number) => {
        if (!content || !editable) return;

        let elements = [...content.elements];
        const item = elements.splice(from, 1)[0];
        elements.splice(to, 0, item);
        elements = elements.map((element, index) => ({
            ...element,
            order: index,
        }));
        dispatch(setElements(elements));

        const newOrder = elements.map(element => ({id: element.id, order: element.order}));
        dispatch(saveElementOrder({
            content_id: content.id,
            order: newOrder,
        }))
    }

    const handleHeadingSave = (newHeading: string) => {
        if (!content) return;

        dispatch(updateContent({
            content_id: content.id,
            name: newHeading,
        }))
    }

    return (<div className={styles.activeSectionContainer}>
        {content && <EditableHeading onSave={handleHeadingSave}>{content.name}</EditableHeading>}
        <form className={styles.contentForm} ref={contentForm}>
            {content?.elements.map((element, index) => <ElementDisplay 
                key={element.id} 
                content={content} 
                element={element} 
                editable={editable} 
                index={index}
                moveElement={moveElement}
            />)}
        </form>
        
        {editable &&
            (addingElement
                ? <form onSubmit={saveAddingElement} className={`${utilStyles.componentForm} ${styles.addingElementForm}`}>
                    <select className={utilStyles.formInput} name="type" value={addingElement.type || undefined} onChange={handleTypeChange}>
                        <option value={ElementType.heading}>Heading</option>
                        <option value={ElementType.text}>Text</option>
                        <option value={ElementType.image}>Image</option>
                    </select>
                    <input className={utilStyles.formInput} type='text' name='name' value={addingElement.name} onChange={handleNameChange} placeholder="Element title" />
                    <input className={utilStyles.formInput} type='text' name='prompt' value={addingElement.prompt} onChange={handlePromptChange} placeholder="Prompt" />
                    <div className={utilStyles.formButtons}>
                        <button className={utilStyles.buttonDanger} type="button" onClick={() => setAddingElement(null)}>Cancel</button>
                        <button className={utilStyles.buttonPrimary} type="submit">Save</button>
                    </div>
                </form>
                : <button className={utilStyles.buttonSecondary} onClick={() => setAddingElement({
                    type: ElementType.heading,
                    name: '',
                    value: '',
                })}>+</button>)
        }

        <div className={styles.buttons}>
            {content?.status === ContentStatus.Approved
                ? <button className={utilStyles.buttonDanger} onClick={handleRevokeApproval}>Revoke Approval</button>
                : <>
                    <div></div>
                    <div className={utilStyles.formButtons}>
                        <button onClick={handleFormSave} className={utilStyles.buttonSecondary}>Save</button>
                        <button onClick={handleFormApprove} className={utilStyles.buttonPrimary}>Approve</button>
                    </div>
                </>}
        </div>

        {content && <Notes notes={content.notes} type={NotableType.Content} notable_id={content.id} />}
        {loading && <LoadingOverlay />}
    </div>);
}
import { useState } from "react";
import { ContentStatus, Element } from "../../../../lib/types";

import styles from './ShortText.module.css';

type ShortTextProps = {
    element: Element,
    status?: ContentStatus,
};

export default function ShortText({ element, status }: ShortTextProps) {
    const [copied, setCopied] = useState(false);

    const copyText = (text: string | undefined) => {
        navigator.clipboard.writeText(text ?? '');

        setCopied(true);
    };

    return (<>
        {status === ContentStatus.Approved
            ? <p className={styles.content}>
                {element.value}
                <button type="button" className={styles.copyButton} onClick={() => copyText(element.value)}>
                    <img src={copied ? "/assets/images/icons/check-solid.svg" : "/assets/images/icons/copy-regular.svg"} alt="" />
                </button>
            </p>
            : <input className={styles.textInput} type="text" name={element.id.toString()} defaultValue={element.value} />}
    </>);
}
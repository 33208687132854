import { configureStore } from '@reduxjs/toolkit';
import contentSlice from './features/content/contentSlice';
import pageSlice from './features/page/pageSlice';
import projectReducer from './features/project/projectSlice';
import userReducer from './features/user/userSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        project: projectReducer,
        page: pageSlice,
        content: contentSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
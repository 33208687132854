import { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { fetchCurrentUser } from '../../features/user/userSlice';
import { post } from '../../services/api/api.service';
import { AppDispatch, RootState } from '../../store';
import styles from './Login.module.css';
import utilStyles from '../../styles/Utils.module.css';

export default function Login() {
    const dispatch = useDispatch<AppDispatch>();
    const {value: user, authorized} = useSelector((state: RootState) => state.user);

    const submitLogin = async (e: FormEvent) => {
        e.preventDefault();
        const target: typeof e.target & {
            email?: HTMLInputElement,
            password?: HTMLInputElement,
        } = e.target;
        if (! (target.email && target.password)) {
            return;
        }

        const data = {
            email: target.email.value,
            password: target.password.value,
        };

        try {
            const response = await post('login', data, false);
            localStorage.setItem('token', response.token);
            dispatch(fetchCurrentUser());
        } catch (error) {
            console.error(error);
        }
    }
    
    return (<>
    {(authorized && user)
        ? <Navigate 
            to={user.projects.length === 1 
                ? `/projects/${user.projects[0].id}/dashboard` 
                : "/projects"} 
            replace={true} />
        : <div className={styles.page}>
            <form className={styles.loginForm} onSubmit={submitLogin}>
                <img src='/logo.svg' alt='sarox logo' className="mb-4" />
                <input type="email" name="email" placeholder='Email' className={utilStyles.formInput} required />
                <input type="password" name="password" placeholder='Password' className={utilStyles.formInput} required />
                <input type="submit" value="Login" className={utilStyles.buttonPrimary} />
            </form>
        </div>}
    </>);
}
import { ChangeEvent, FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Layout from "../../components/layout/layout";
import { AddingProject, addProject } from "../../features/user/userSlice";
import { AppDispatch, RootState } from "../../store";
import classNames from "classnames";

import styles from './Projects.module.css';
import utilStyles from '../../styles/Utils.module.css';

type ProjectsProps = {};

export default function Projects({}: ProjectsProps) {
    const user = useSelector((state: RootState) => state.user);
    const projects = user.value?.projects;
    const loading = user.loading;
    const [addingProject, setAddingProject] = useState<AddingProject|null>(null);
    const dispatch = useDispatch<AppDispatch>();

    const handleAddingProjectNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAddingProject({
            ...addingProject,
            name: e.target.value,
        });
    }

    const handleAddingProjectFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (! addingProject) return;

        dispatch(addProject(addingProject));
        setAddingProject(null);
    }

    return (<Layout>
        <div className={styles.projectPage}>
            <h2 className={styles.header}>Projects</h2>

            <div className={styles.projectGrid}>
                {projects?.map((project) => <Link to={`${project.id}/dashboard`} key={project.id}>
                    <div className={styles.projectCard}>
                        {project.name}
                    </div>
                </Link>)}
                {addingProject 
                    ? <form className={styles.projectCard} onSubmit={handleAddingProjectFormSubmit}>
                        <input 
                            className={utilStyles.formInput} 
                            type="text" 
                            value={addingProject.name} 
                            onChange={handleAddingProjectNameChange} 
                            placeholder="Project name" />
                        <button
                        className={classNames({
                                [utilStyles.buttonPrimary]: true,
                                [utilStyles.loading]: loading,
                            })}
                            disabled={loading}
                            type="submit">{loading ? 'Saving' : 'Save'}</button>
                    </form>
                    :<button 
                        className={styles.projectCard}
                        onClick={() => setAddingProject({name: '', user_id: 1})}>
                        +
                    </button>}
            </div>
        </div>
    </Layout>);
}
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Element, ContentStatus } from "../../../../lib/types";

import styles from "./LongText.module.css";


type ShortTextProps = {
    element: Element,
    status?: ContentStatus,
};

export default function LongText({ element, status }: ShortTextProps) {
    const [copied, setCopied] = useState(false);

    const copyText = (text: string | undefined) => {
        navigator.clipboard.writeText(text ?? '');
        
        setCopied(true);
    };
    
    return (<>
        {status === ContentStatus.Approved
            ? <div className={styles.content}>
                {element.value && <ReactMarkdown>{element.value}</ReactMarkdown>}
                <button type="button" className={styles.copyButton} onClick={() => copyText(element.value)}>
                    <img src={copied ? "/assets/images/icons/check-solid.svg" : "/assets/images/icons/copy-regular.svg"} alt="" />
                </button>
            </div>
            : <textarea className={styles.textArea} name={element.id.toString()} defaultValue={element.value}></textarea>}
        </>);
}
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { addColor } from "../../../features/project/projectSlice";
import { AppDispatch } from "../../../store";
import { Color, DashboardProject } from "../projectDashboard";

import styles from './ProjectPalette.module.css';
import utilStyles from '../../../styles/Utils.module.css';

type ProjectPaletteProps = {
    project: DashboardProject,
}

export default function ProjectPalette({ project }: ProjectPaletteProps) {
    const [addingColor, setAddingColor] = useState<Color|null>(null);
    const dispatch = useDispatch<AppDispatch>();

    const handleColorNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!addingColor) return;

        setAddingColor({
            ...addingColor,
            name: e.target.value,
        });
    };

    const handleColorHexChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!addingColor) return;

        setAddingColor({
            ...addingColor,
            hex: e.target.value,
        });
    };

    const saveAddingColor = async () => {
        if (! addingColor) return;

        dispatch(addColor({palette_id: project.palette.id, ...addingColor}));
    }
    
    return (<div className={styles.projectPalette}>
        <h2>Color Palette</h2>
        <div className={styles.colorList}>
            {project.palette.colors.map(color => <div className={styles.colorItem} key={color.id}>
                <div className={styles.colorItemText}>{color.name}:</div> <div 
                    className={styles.colorSquare}
                    style={{
                        backgroundColor: `#${color.hex}`,
                    }}
                    onClick={() => navigator.clipboard.writeText(color.hex)}>#{color.hex}</div>
            </div>)}
            {addingColor 
                ? <div className={utilStyles.componentForm}>
                    <input type="text" value={addingColor.name} onChange={handleColorNameChange} placeholder="Name" className={utilStyles.formInput} />
                    <input type="text" value={addingColor.hex} onChange={handleColorHexChange} placeholder="HEX" className={utilStyles.formInput} />
                    <div className={utilStyles.formButtons}>
                        <button type="button" onClick={() => setAddingColor(null)} className={utilStyles.buttonLink}>Cancel</button>
                        <button type="button" onClick={saveAddingColor} className={utilStyles.buttonPrimary}>Save</button>
                    </div>
                </div>
                : <button className={utilStyles.buttonPrimary} onClick={() => setAddingColor({name: '', hex: ''})}>+</button>
            }
        </div>
    </div>)
}
import './App.css';
import { Routes, Route } from 'react-router-dom';
import './index.css';
import Login from './pages/login/login';
import Projects from './pages/projects/projects';
import Project from './pages/project/project';
import ActivePage from './components/activePage/activePage';
import ActiveSection from './components/activeSection/activeSection';
import ProjectDashboard from './components/projectDashboard/projectDashboard';
import Home from './components/pages/home/home';

function App() {
  // TODO: add user slice and load user on initial load/redirect to login

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/projects/:projectId" element={<Project />}>
        <Route path="dashboard" element={<ProjectDashboard />} />
        <Route path="pages/:pageId" element={<ActivePage />}>
          <Route path="sections/:contentId" element={<ActiveSection />} />
        </Route>
      </Route>
    </Routes>);
}

export default App;

import { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import { Page } from "../../../lib/types";
import classNames from "classnames";

import styles from "./PageLink.module.css";

type PageLinkProps = {
    page: Page,
    active: boolean,
}

export default function PageLink({ page, active }: PageLinkProps) {

    return (<>
        <Link to={`pages/${page.id}`}>
            <div 
                className={classNames({
                    [styles.item]: true,
                    [styles.active]: active,
                }) }>
                <h1>{page.name} {page.in_nav ? <span>(Nav page)</span> : <></>}</h1>
                
            </div>
        </Link>
    </>);
}
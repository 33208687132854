import { Link } from "react-router-dom";
import { ContentStatus, SectionContent } from "../../../lib/types";
import classNames from 'classnames';

import styles from './SectionContent.module.css';

type SectionContentDisplayProps = {
    content: SectionContent,
};

export default function SectionContentDisplay({ content }: SectionContentDisplayProps) {

    return (<Link to={`sections/${content.id}`}>
        <div className={classNames({
            [styles.sectionContent]: true,
            [styles.draft]: content.status === ContentStatus.Draft,
            [styles.approved]: content.status === ContentStatus.Approved,
        })}>
            <strong>{content.id}: </strong>
            <span>{content.name}</span>
        </div>
    </Link>)
}
type Method = 'GET' | 'POST' | 'PUT' | 'DELETE';

type FormMethod = 'POST' | 'PUT';

type Headers = {
    'Content-Type'?: string,
    'Accept': string,
    Authorization?: string,
}
type FetchOptions = {
    method: Method,
    mode: 'cors' | 'no-cors', 
    headers: Headers,
    body?: string,
};

const _base = process.env.REACT_APP_API_BASE || 'http://localhost/api/';

const _formRequest = async (method: FormMethod, endpoint: string, data: any, authenticated: boolean = true) => {    
    try {
        let headers: Headers = {
            'Accept': 'application/json',
        };

        if (authenticated) {
            const token = localStorage.getItem('token');
            headers.Authorization = `Bearer ${token}`;
        }

        const options: FetchOptions = {
            method,
            mode: 'cors', // TODO
            headers,
            body: data,
        };

        const response = await fetch(`${_base}${endpoint}`, options);
        switch (response.status) {
            case 200:
            case 201:
                return await response.json();
            case 204:
            default:
                return null;
        };
    } catch (error) {
        console.error(error);
    }
}

const _request = async (method: Method, endpoint: string, data: any = {}, authenticated: boolean = true) => {
    let headers: Headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    };

    if (authenticated) {
        const token = localStorage.getItem('token');
        headers.Authorization = `Bearer ${token}`;
    }

    const options: FetchOptions = {
        method,
        mode: 'cors', // TODO
        headers,
    };
    if (method !== 'GET' && data) {
        options.body = JSON.stringify(data);
    }
    const response = await fetch(`${_base}${endpoint}`, options);
    return await response.json();
}

const get = async (endpoint: string, authenticated: boolean = true) => {
    return _request('GET', endpoint)
        .then(response => response);
}

const getResource = async (endpoint: string, authenticated: boolean = true) => {
    return _request('GET', endpoint)
        .then(response => response?.data);
}

const post = async (endpoint: string, data: any = {}, authenticated: boolean = true) => {
    return _request('POST', endpoint, data, authenticated)
        .then(response => response);
}

const postResource = async (endpoint: string, data: any = {}) => {
    return _request('POST', endpoint, data)
        .then(response => response?.data);
}

const postFormResource = async (endpoint: string, data: any) => {
    return _formRequest('POST', endpoint, data)
        .then(response => response?.data);
}

const put = async (endpoint: string, data: any = {}, authenticated: boolean = true) => {
    return _request('PUT', endpoint, data, authenticated)
        .then(response => response);
}

const putResource = async (endpoint: string, data: any = {}) => {
    return _request('PUT', endpoint, data)
        .then(response => response?.data);
}

const putFormResource = async (endpoint: string, data: any) => {
    return _formRequest('PUT', endpoint, data)
        .then(response => response.data);
}

const callDelete = async (endpoint: string, authenticated: boolean = true) => {
    return await _request('DELETE', endpoint, {}, authenticated);
}

export { get, getResource, post, postResource, postFormResource, put, putResource, putFormResource, callDelete };